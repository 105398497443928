import BookAppoinment from "./BookAppoinment";

export default function Hero() {

    const scroll = () => {
        const section = document.querySelector( '#about_scroll' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
        <div className="h-screen m-auto bg-cover bg-left" style={{backgroundImage: `url('hero_new.png')`}}>

            <div className="mx-auto max-w-full px-14 pb-24 sm:pb-32 lg:flex lg:py-20">
                <div className="mx-auto max-w-full flex-shrink-0 lg:mx-0 lg:max-w-3xl lg:pt-60 pt-16">

                    <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                        Welcome to PoundsPooches
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-100">
                        Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
                        Elit sunt amet.
                    </p>
                    <div className="mt-8 flex items-center gap-x-6">
                        <BookAppoinment/>
                        <a href="#" className="text-sm font-semibold leading-6 text-white">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
            </div>

            <div className="hidden absolute inset-x-0 top-[calc(100%-4.5rem)] sm:hidden" aria-hidden="true">
                <img src="paws.png" className="h-[3.5rem] w-[3.5rem] z-15 mx-auto animate-float cursor-pointer" onClick={scroll}/>

            </div>

        </div>
    )
}


