import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon} from '@heroicons/react/20/solid'
import {RadioGroup} from '@headlessui/react'
import api from '../services/api'
import { useForm } from "react-hook-form";


const steps = [
    {id: 'Step 1', name: 'Choose an appointment', href: '#', status: 'current'},
    {id: 'Step 2', name: 'Select services', href: '#', status: 'upcoming'},
    {id: 'Step 3', name: 'Your details', href: '#', status: 'upcoming'},
    {id: 'Step 4', name: 'Confirm & book', href: '#', status: 'upcoming'},
]

const morning = [
    {name: '8:00', inStock: true},
    {name: '9:00', inStock: true},
    {name: '10:00', inStock: true},
    {name: '11:00', inStock: true},
]

const afternoon = [
    {name: '12:00', inStock: true},
    {name: '13:00', inStock: true},
    {name: '14:00', inStock: true},
    {name: '15:00', inStock: true},
    {name: '16:00', inStock: true},
]

const months = [
    {date: '2021-12-27'},
    {date: '2021-12-28'},
    {date: '2021-12-29'},
    {date: '2021-12-30'},
    {date: '2021-12-31'},
    {date: '2022-01-01', isCurrentMonth: true},
    {date: '2022-01-02', isCurrentMonth: true},
    {date: '2022-01-03', isCurrentMonth: true},
    {date: '2022-01-04', isCurrentMonth: true},
    {date: '2022-01-05', isCurrentMonth: true},
    {date: '2022-01-06', isCurrentMonth: true},
    {date: '2022-01-07', isCurrentMonth: true},
    {date: '2022-01-08', isCurrentMonth: true},
    {date: '2022-01-09', isCurrentMonth: true},
    {date: '2022-01-10', isCurrentMonth: true},
    {date: '2022-01-11', isCurrentMonth: true},
    {date: '2022-01-12', isCurrentMonth: true, isToday: true},
    {date: '2022-01-13', isCurrentMonth: true},
    {date: '2022-01-14', isCurrentMonth: true},
    {date: '2022-01-15', isCurrentMonth: true},
    {date: '2022-01-16', isCurrentMonth: true},
    {date: '2022-01-17', isCurrentMonth: true},
    {date: '2022-01-18', isCurrentMonth: true},
    {date: '2022-01-19', isCurrentMonth: true},
    {date: '2022-01-20', isCurrentMonth: true},
    {date: '2022-01-21', isCurrentMonth: true},
    {date: '2022-01-22', isCurrentMonth: true},
    {date: '2022-01-23', isCurrentMonth: true},
    {date: '2022-01-24', isCurrentMonth: true},
    {date: '2022-01-25', isCurrentMonth: true},
    {date: '2022-01-26', isCurrentMonth: true},
    {date: '2022-01-27', isCurrentMonth: true},
    {date: '2022-01-28', isCurrentMonth: true},
    {date: '2022-01-29', isCurrentMonth: true},
    {date: '2022-01-30', isCurrentMonth: true},
    {date: '2022-01-31', isCurrentMonth: true},
    {date: '2022-02-01'},
    {date: '2022-02-02'},
    {date: '2022-02-03'},
    {date: '2022-02-04'},
    {date: '2022-02-05'},
    {date: '2022-02-06'},
]

const tabs = [
    { name: 'Morning', href: '#', current: true },
    { name: 'Afternoon', href: '#', current: false },
]

const statuses = {


    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const projects = [
    {
        id: 1,
        name: 'GraphQL API',
        href: '#',
        status: 'Complete',
        createdBy: 'Leslie Alexander',
        dueDate: 'March 17, 2023',
        dueDateTime: '2023-03-17T00:00Z',
    },
    {
        id: 2,
        name: 'New benefits plan',
        href: '#',
        status: 'In progress',
        createdBy: 'Leslie Alexander',
        dueDate: 'May 5, 2023',
        dueDateTime: '2023-05-05T00:00Z',
    },
    {
        id: 3,
        name: 'Onboarding emails',
        href: '#',
        status: 'In progress',
        createdBy: 'Courtney Henry',
        dueDate: 'May 25, 2023',
        dueDateTime: '2023-05-25T00:00Z',
    },
    {
        id: 4,
        name: 'iOS app',
        href: '#',
        status: 'In progress',
        createdBy: 'Leonard Krasner',
        dueDate: 'June 7, 2023',
        dueDateTime: '2023-06-07T00:00Z',
    },
    {
        id: 5,
        name: 'Marketing site redesign',
        href: '#',
        status: 'Archived',
        createdBy: 'Courtney Henry',
        dueDate: 'June 10, 2023',
        dueDateTime: '2023-06-10T00:00Z',
    },
]

const detailForms = [
    {id: "default"},
    {id: "create_account"},
    {id: "guest"}
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function BookAppoinment() {
    const [open, setOpen] = useState(false)
    const [date, setDate] = useState(months[10].date)
    const [chosenTime, setChosenTime] = useState(null)
    const [currentStep, setCurrentStep] = useState(0)
    const [total, setTotal] = useState(0.00)
    const [detailForm, setDetailForm] = useState(0)
    const { register, handleSubmit, watch, getValues, formState: { errors } } = useForm();

    const handleClick =  async (event) => {
        // Prevent page reload
        event.preventDefault();

        await api.post('/book', { date: date, time: chosenTime, total: total })
            .then(response => {
                console.log(response);
            })
            .catch((response) => {
            })
            .then(() => {

            })
    };

    const createAccount = async (data) => {
        console.log(data)
    }


    return (
        <>
            <a onClick={() => setOpen(true)}
               className="cursor-pointer rounded-md bg-amber-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500">
                Book Now
            </a>
            <Transition.Root show={open} as={Fragment}>
                <Dialog className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div
                            className="flex min-h-full h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-8 sm:w-full lg:max-w-5xl sm:max-w-full sm:p-6 text-center">
                                    {/* Close modal */}
                                    <div className="fixed top-4 right-4">
                                        <svg onClick={() => setOpen(false)} xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor"
                                             className="w-6 h-6 cursor-pointer hover:animate-spin">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M6 18 18 6M6 6l12 12"/>
                                        </svg>
                                    </div>

                                    {/* Modal body */}
                                    <div className="p-6">

                                        {/* Steps */}
                                        <nav aria-label="Progress">
                                            <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                                                {steps.map((step, stepKey) => (
                                                    <li key={stepKey} className="md:flex-1">
                                                        {currentStep > stepKey ? (
                                                            <a
                                                                href={step.href}
                                                                className=" cursor-default group flex flex-col border-l-4 border-amber-600 py-2 pl-4 hover:border-amber-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                                            >
                                                            <span
                                                                className="text-sm font-medium text-amber-600 group-hover:text-amber-800">{step.id}</span>
                                                                <span className="text-sm font-medium">{step.name}</span>
                                                            </a>
                                                        ) : currentStep === stepKey ? (
                                                            <a
                                                                href={step.href}
                                                                className="cursor-default flex flex-col border-l-4 border-amber-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                                                aria-current="step"
                                                            >
                                                            <span
                                                                className="text-sm font-medium text-amber-600">{step.id}</span>
                                                                <span className="text-sm font-medium">{step.name}</span>
                                                            </a>
                                                        ) : (
                                                            <a
                                                                href={step.href}
                                                                className="cursor-default group flex flex-col border-l-4 border-gray-200 py-2 pl-4  md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                                            >
                                                            <span
                                                                className="text-sm font-medium text-gray-500">{step.id}</span>
                                                                <span className="text-sm font-medium">{step.name}</span>
                                                            </a>
                                                        )}
                                                    </li>
                                                ))}
                                            </ol>
                                        </nav>

                                        {currentStep === 0 &&
                                            <div className="max-w-4xl m-auto mt-4">

                                                <section className="text-center">
                                                    <h2 className="text-xl font-semibold text-gray-500">May</h2>
                                                    <div
                                                        className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500">
                                                        <div>M</div>
                                                        <div>T</div>
                                                        <div>W</div>
                                                        <div>T</div>
                                                        <div>F</div>
                                                        <div>S</div>
                                                        <div>S</div>
                                                    </div>
                                                    <div
                                                        className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                                                        {months.map((day, dayIdx) => (
                                                            <button
                                                                key={day.date}
                                                                type="button"
                                                                className={classNames(
                                                                    day.isCurrentMonth ? 'bg-white text-gray-900' : 'bg-gray-50 text-gray-400',
                                                                    dayIdx === 0 && 'rounded-tl-lg',
                                                                    dayIdx === 6 && 'rounded-tr-lg',
                                                                    dayIdx === months.length - 7 && 'rounded-bl-lg',
                                                                    dayIdx === months.length - 1 && 'rounded-br-lg',
                                                                    'py-1.5 hover:bg-gray-100 focus:z-10',
                                                                )}
                                                                onClick={() => setDate(day.date)}
                                                            >
                                                                <time dateTime={day.date}
                                                                      className={classNames(
                                                                          date === day.date && 'bg-amber-600 font-semibold text-white',
                                                                          'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                                                                      )}
                                                                >
                                                                    {day.date.split('-').pop().replace(/^0/, '')}
                                                                </time>

                                                                <div
                                                                    className="h-1 bg-emerald-200 w-12 mx-auto mt-2"></div>
                                                            </button>
                                                        ))}
                                                    </div>
                                                </section>

                                                <div className="mt-6 bg-gray-100 p-2 rounded-lg">
                                                    <div className="sm:hidden">
                                                        <label htmlFor="tabs" className="sr-only">
                                                            Select a tab
                                                        </label>
                                                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                                        <select
                                                            id="tabs"
                                                            name="tabs"
                                                            className="block w-full rounded-md border-gray-300 focus:border-amber-500 focus:ring-amber-500"

                                                        >
                                                            {tabs.map((tab) => (
                                                                <option key={tab.name}>{tab.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="hidden sm:block">
                                                        <nav
                                                            className=" rounded-md shadow-sm grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2"
                                                            aria-label="Tabs">
                                                            {tabs.map((tab) => (
                                                                <a
                                                                    key={tab.name}
                                                                    href={tab.href}
                                                                    className={classNames(
                                                                        tab.current ? 'bg-white text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                                                        'rounded-md py-2 text-sm font-medium px-6 col-span-1'
                                                                    )}
                                                                    aria-current={tab.current ? 'page' : undefined}
                                                                >
                                                                    {tab.name}
                                                                </a>
                                                            ))}
                                                        </nav>
                                                    </div>

                                                    <RadioGroup value={chosenTime} onChange={setChosenTime}
                                                                className="mt-6">
                                                        <RadioGroup.Label className="sr-only">Choose a memory
                                                            option</RadioGroup.Label>
                                                        <div className="grid grid-cols-3 gap-2 sm:grid-cols-4">
                                                            {morning.map((option) => (
                                                                <RadioGroup.Option
                                                                    key={option.name}
                                                                    value={option}
                                                                    className={({active, checked}) =>
                                                                        classNames(
                                                                            option.inStock ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
                                                                            active ? 'ring-2 ring-amber-600 ring-offset-2' : '',
                                                                            checked
                                                                                ? 'bg-amber-600 text-white hover:bg-amber-500'
                                                                                : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                                                            'flex items-center justify-center rounded-md py-3 px-6 text-sm font-semibold uppercase sm:flex-1'
                                                                        )
                                                                    }
                                                                    disabled={!option.inStock}
                                                                >
                                                                    <RadioGroup.Label
                                                                        as="span">{option.name}</RadioGroup.Label>
                                                                </RadioGroup.Option>
                                                            ))}
                                                        </div>
                                                    </RadioGroup>

                                                </div>

                                            </div>
                                        }

                                        {currentStep === 1 &&
                                            <div>
                                            <ul role="list" className="divide-y divide-gray-100 mt-6">
                                                {projects.map((project) => (
                                                    <li key={project.id}
                                                        className="flex items-center justify-between gap-x-6 py-5">
                                                        <div className="min-w-0">
                                                            <div className="flex items-start gap-x-3">
                                                                <p className="text-sm font-semibold leading-6 text-gray-900">{project.name}</p>

                                                            </div>
                                                            <div
                                                                className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                                <p className="whitespace-nowrap">
                                                                    Due on <time
                                                                    dateTime={project.dueDateTime}>{project.dueDate}</time>
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <div className="flex flex-none items-center gap-x-4">
                                                            <a
                                                                href={project.href}
                                                                className="hidden rounded-md bg-amber-600 text-white px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-amber-500 sm:block"
                                                            >
                                                                Select Product<span
                                                                className="sr-only">, {project.name}</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                                <div className="flex items-center justify-between pt-4 border-t border-amber-100 mt-4">
                                                    <dt className="font-medium text-gray-900">Total</dt>
                                                    <dd className="font-medium text-amber-600">£{parseFloat(total).toFixed(2)}</dd>
                                                </div>
                                            </div>
                                        }

                                        {currentStep === 2 && detailForms[detailForm].id === 'default' &&

                                            <div className="flex flex-1 flex-col justify-center py-6 sm:px-6 lg:px-8">
                                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-amber-600">
                                                        Sign in to your account
                                                    </h2>
                                                </div>

                                                <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px] text-left">
                                                    <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-12">
                                                        <form className="space-y-6" action="#" method="POST">
                                                            <div>
                                                                <label htmlFor="email"
                                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Email address
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        id="email"
                                                                        name="email"
                                                                        type="email"
                                                                        autoComplete="email"
                                                                        required
                                                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <label htmlFor="password"
                                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Password
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        id="password"
                                                                        name="password"
                                                                        type="password"
                                                                        autoComplete="current-password"
                                                                        required
                                                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center">
                                                                    <input
                                                                        id="remember-me"
                                                                        name="remember-me"
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                                                                    />
                                                                    <label htmlFor="remember-me"
                                                                           className="ml-3 block text-sm leading-6 text-gray-900">
                                                                        Remember me
                                                                    </label>
                                                                </div>

                                                                <div className="text-sm leading-6">
                                                                    <a href="#"
                                                                       className="font-semibold text-amber-600 hover:text-amber-500">
                                                                        Forgot password?
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                                                                >
                                                                    Sign in
                                                                </button>
                                                            </div>
                                                        </form>

                                                        <div>
                                                            <div className="relative mt-6">
                                                                <div className="absolute inset-0 flex items-center"
                                                                     aria-hidden="true">
                                                                    <div className="w-full border-t border-gray-200"/>
                                                                </div>
                                                                <div
                                                                    className="relative flex justify-center text-sm font-medium leading-6">
                                                                    <span className="bg-white px-6 text-gray-900">Or</span>
                                                                </div>
                                                            </div>

                                                            <div className="mt-6 grid grid-cols-1 gap-4">
                                                                <button
                                                                    onClick={() => setDetailForm(1)}
                                                                    type="submit"
                                                                    className="flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                                                                >
                                                                    Create an account
                                                                </button>

                                                                <button
                                                                    type="submit"
                                                                    className="flex w-full justify-center rounded-md bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                                                                >
                                                                    Continue as a guest
                                                                </button>

                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>


                                            </div>
                                        }

                                        {currentStep === 2 && detailForms[detailForm].id === 'create_account' &&

                                            <div className="flex flex-1 flex-col justify-center text-left py-6 sm:px-6 lg:px-8">
                                                <form onSubmit={handleSubmit(createAccount)}>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                                        This information will be used to contact you
                                                    </p>
                                                    <div
                                                        className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2 mt-2">
                                                        <div className="sm:col-span-3">
                                                            <label htmlFor="first-name"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                First name
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    {...register("firstName", {required: "A first name is required"})}
                                                                    type="text"
                                                                    autoComplete="given-name"
                                                                    className={classNames(
                                                                        errors.firstName && 'ring-red-500 text-red-600 focus:ring-red-600',
                                                                        'block w-full outline-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6'
                                                                    )}
                                                                />
                                                                <p className="text-sm text-red-600">{errors.firstName?.message}</p>
                                                            </div>
                                                        </div>

                                                        <div className="sm:col-span-3">
                                                            <label htmlFor="last-name"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Last name
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    {...register("lastName", {
                                                                        required: "A last name is required",
                                                                    })}
                                                                    type="text"
                                                                    autoComplete="family-name"
                                                                    className={classNames(
                                                                        errors.lastName && 'ring-red-500 text-red-600 focus:ring-red-600',
                                                                        'block w-full outline-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6'
                                                                    )}/>
                                                                    <p className="text-sm text-red-600">{errors.lastName?.message}</p>
                                                            </div>

                                                        </div>

                                                        <div className="sm:col-span-3">
                                                            <label htmlFor="email"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Email address
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    {...register("email", {required: "An email address is required"})}
                                                                    type="email"
                                                                    autoComplete="email"
                                                                    className={classNames(
                                                                        errors.email && 'ring-red-500 text-red-600 focus:ring-red-600',
                                                                        'block w-full outline-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6'
                                                                    )}
                                                                />
                                                                <p className="text-sm text-red-600">{errors.email?.message}</p>
                                                            </div>
                                                        </div>

                                                        <div className="sm:col-span-3">
                                                            <label htmlFor="email"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Confirm Email address
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    {...register("emailConfirmation", {
                                                                        required: "Please confirm email!",
                                                                        validate: {
                                                                            matchesPreviousEmail: (value) => {
                                                                                const {email} = getValues();
                                                                                return email === value || "Emails should match!";
                                                                            }
                                                                        }
                                                                    })}
                                                                    type="email"
                                                                    autoComplete="email"
                                                                    className={classNames(
                                                                        errors.emailConfirmation && 'ring-red-500 text-red-600 focus:ring-red-600',
                                                                        'block w-full outline-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6'
                                                                    )}                                                                />
                                                                <p className="text-sm text-red-600">{errors.emailConfirmation?.message}</p>
                                                            </div>
                                                        </div>

                                                        <div className="sm:col-span-3">
                                                            <label htmlFor="email"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Password
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    {...register("password", {required: "A password is required"})}
                                                                    type="password"
                                                                    autoComplete="password"
                                                                    className={classNames(
                                                                        errors.password && 'ring-red-500 text-red-600 focus:ring-red-600',
                                                                        'block w-full outline-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6'
                                                                    )}
                                                                />
                                                                <p className="text-sm text-red-600">{errors.password?.message}</p>
                                                            </div>
                                                        </div>

                                                        <div className="sm:col-span-3">
                                                            <label htmlFor="email"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Confirm Password
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    {...register("passwordConfirmation", {
                                                                        required: "Please confirm password!",
                                                                        validate: {
                                                                            matchesPreviousEmail: (value) => {
                                                                                const {password} = getValues();
                                                                                return password === value || "Passwords should match!";
                                                                            }
                                                                        }
                                                                    })}
                                                                    type="password"
                                                                    autoComplete="password"
                                                                    className={classNames(
                                                                        errors.passwordConfirmation && 'ring-red-500 text-red-600 focus:ring-red-600',
                                                                        'block w-full outline-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6'
                                                                    )}/>
                                                                <p className="text-sm text-red-600">{errors.passwordConfirmation?.message}</p>
                                                            </div>
                                                        </div>

                                                        <div className="sm:col-span-6">
                                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Address</h2>
                                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                                This information will used for invoices and contact
                                                            </p>
                                                        </div>


                                                        <div className="col-span-full">
                                                            <label htmlFor="street-address"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Start typing address below
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    type="text"
                                                                    name="street-address"
                                                                    id="street-address"
                                                                    autoComplete="street-address"
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="col-span-full">
                                                            <button type="submit" className="float-right ml-2 rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500">
                                                                Create account
                                                            </button>
                                                        </div>


                                                    </div>
                                                </form>
                                            </div>
                                        }

                                        {currentStep === 3 &&
                                            <div className="lg:col-start-3 lg:row-end-1 mt-6 max-w-xl m-auto text-left">
                                                <h2 className="sr-only">Summary</h2>
                                                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                                    <dl className="flex flex-wrap">
                                                        <div className="flex-auto pl-6 pt-6">
                                                            <dt className="text-sm font-semibold leading-6 text-gray-900">Amount</dt>
                                                            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">£{parseFloat(total).toFixed(2)}</dd>
                                                        </div>

                                                        <div
                                                            className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                                                            <dt className="flex-none">
                                                            <span className="sr-only">Client</span>
                                                                <UserCircleIcon className="h-6 w-5 text-gray-400"
                                                                                aria-hidden="true"/>
                                                            </dt>
                                                            <dd className="text-sm font-medium leading-6 text-gray-900">Alex
                                                                Curren
                                                            </dd>
                                                        </div>
                                                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                            <dt className="flex-none">
                                                                <span className="sr-only">Due date</span>
                                                                <CalendarDaysIcon className="h-6 w-5 text-gray-400"
                                                                                  aria-hidden="true"/>
                                                            </dt>
                                                            <dd className="text-sm leading-6 text-gray-500">
                                                                <time dateTime="2023-01-31">January 31, 2023</time>
                                                            </dd>
                                                        </div>
                                                        <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                            <dt className="flex-none">
                                                                <span className="sr-only">Status</span>
                                                                <CreditCardIcon className="h-6 w-5 text-gray-400"
                                                                                aria-hidden="true"/>
                                                            </dt>
                                                            <dd className="text-sm leading-6 text-gray-500">Paid with
                                                                MasterCard
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                                                        <a href="#"
                                                           className="text-sm font-semibold leading-6 text-gray-900">
                                                            Download invoice <span aria-hidden="true">&rarr;</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </div>
                                    <div className="mt-2 text-right">
                                        {currentStep === 0 &&
                                            <button onClick={() => setCurrentStep(parseInt(currentStep + 1))}
                                                    disabled={chosenTime === null} type="button"
                                                    className="disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed float-right ml-6 rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500">Next
                                            </button>
                                        }

                                        {currentStep === 1 &&
                                            <div>

                                                <button onClick={() => setCurrentStep(parseInt(currentStep + 1))}
                                                        disabled={chosenTime === null} type="button"
                                                        className="disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed float-right ml-2 rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500">Next
                                                </button>

                                                <button onClick={() => setCurrentStep(parseInt(currentStep - 1))}
                                                        disabled={chosenTime === null} type="button"
                                                        className="disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed float-right ml-6 rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">Back
                                                </button>
                                            </div>
                                        }

                                        {currentStep === 2 &&
                                            <div>
                                                <button onClick={() => setCurrentStep(parseInt(currentStep - 1))}
                                                        disabled={chosenTime === null} type="button"
                                                        className="disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed float-right ml-6 rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">Back
                                                </button>
                                            </div>
                                        }

                                        {currentStep === 3 &&
                                            <div>

                                                <button onClick={handleClick}
                                                        disabled={chosenTime === null} type="button"
                                                        className="disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed float-right ml-2 rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500">Book!
                                                </button>

                                                <button onClick={() => setCurrentStep(parseInt(currentStep - 1))}
                                                        disabled={chosenTime === null} type="button"
                                                        className="disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed float-right ml-6 rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">Back
                                                </button>
                                            </div>
                                        }


                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
