import Header from "../components/Header"
import Hero from "../components/Hero";
import About from "../components/About"
import Footer from "../components/Footer";

function Main() {
    return (
        <div className="App bg-white">
            <Header/>
            <Hero/>
            <About/>
            <Footer/>
        </div>
    );
}

export default Main;
