import axios from "axios";
import TokenService from "./token-service";

const instance = axios.create({
  baseURL: "http://localhost:8099/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    console.log(originalConfig);

    if (originalConfig.url !== "/login" && err.response) {
 
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/refresh", {}, {headers: {'x-refresh-token' : TokenService.getLocalRefreshToken()}});

          const accessToken = rs.data.token;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          window.location.href = '/login';
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
